$('document').ready(function () {

    // Tooltips
    $('.tablesorter').on('pagerComplete', function () {
        $(this).find('.action-buttons button').tooltip({
            delay: {
                'show': 900,
                'hide': 100
            }
        });

        //Dropdowns

    });

    $('a').tooltip({
        delay: {
            'show': 900,
            'hide': 100
        }
    });
});
