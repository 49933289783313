/**
 * Enable filtering a table column by clicking a value in that column.
 */
function bindTableClickToSearch(el) {
    var table = el.find('.tablesorter');
    // Allow filtering by clicking values in columns
    el.find('.js-filter').on('click', function () {
        var filters = $.tablesorter.getFilters(table);
        var columnIndex = $(this).parent('td').index();
        var value = $(this).html();
        filters[columnIndex] = value;
        $.tablesorter.setFilters(table, filters);
    });
}
